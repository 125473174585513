.pdf-container {
  padding-top: 12rem;
}
.react-pdf__Page {
  .react-pdf__Page__canvas {
    width: 100vw !important;
    height: auto !important;
  }
  .react-pdf__Page__annotations,
  .annotationLayer {
    display: none;
  }
  .react-pdf__Page__textContent,
  .textLayer {
    display: none;
  }
}

.mobile {
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}

.small-logo-section {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 226px;
    height: 112px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.project1 {
  padding-top: 13.5rem !important;
}

.project {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-end;
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    color: #000000;
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin: 1rem 0 0 0;
    @media (min-width: 992px) {
      font-size: 48px;
      line-height: 48px;
    }
  }
  .date {
    color: #000000;
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 28px;
    }
  }
  .genre {
    font-weight: normal;
  }
}

.link {
  text-decoration: none;
  color: #0f244f;
}
