.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-menu {
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-section {
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    ),
    url("../../assets/img/BG-01.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 30vh;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) {
    height: 100vh;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  img {
    max-height: 63px;
    max-width: 100%;
    margin-top: 6rem;
    @media (min-width: 992px) {
      margin-top: 14rem;
      max-height: 326px;
    }
  }
}

.name {
  display: none;
  @media (min-width: 992px) {
    display: inline;
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 200 !important;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #0f244f;
    margin-top: 32px;
  }
}

.subtitle {
  display: flex;
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 250;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #0f244f;
  flex-direction: column;
  align-items: flex-start;
  margin: 6rem 0 0 1rem;
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
    font-size: 24px;
    line-height: 24px;
    font-weight: 200;
    margin: 0;
  }
  .dot {
    display: none;
    @media (min-width: 992px) {
      display: inline;
    }
  }
}

.selfIntro-section {
  .selfTitle {
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #0f244f;
  }
  .selfName {
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #0f244f;
    margin: 0.3rem 0;
  }
  .selfIntroduction {
    font-family: "Josefin Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #0f244f;
    margin-top: 1.5rem;
  }
  .selfie {
    width: 100%;
    padding-right: 1rem;
  }
  padding-left: 0.3rem;
  margin-bottom: 5rem;
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
}

.content-pic {
  min-width: 100%;
  max-width: 267px;
  height: 100px;
  @media (min-width: 992px) {
    height: 403px;
  }
}

.content {
  padding-bottom: 1rem;
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  @media (min-width: 992px) {
    padding-top: 1rem;
  }
}

.architecture {
  background: url(../../assets/img/ARC-mobile.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: 992px) {
    background: url(../../assets/img/ARC-Design-b.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &:hover {
      background: url(../../assets/img/ARC-Design-Hover.jpg) no-repeat center
        center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
}

.interiorDesign {
  background: url(../../assets/img/Interior-Design-mobile.png) no-repeat center
    center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: 992px) {
    background: url(../../assets/img/Interior-Design-b.jpg) no-repeat center
      center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &:hover {
      background: url(../../assets/img/Interior-Design-Hover.jpg) no-repeat
        center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      transition: 0.2s;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }
  }
}

.UXUIDesign {
  background: url(../../assets/img/UXUI-mobile.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: 992px) {
    background: url(../../assets/img/UXUI-Design-b.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &:hover {
      background: url(../../assets/img/UXUI-Design-Hover.jpg) no-repeat center
        center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      transition: 0.2s;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }
  }
}

.modelDesign {
  background: url(../../assets/img/Model-mobile.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (min-width: 992px) {
    background: url(../../assets/img/Graphic-Design-b.jpg) no-repeat center
      center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &:hover {
      background: url(../../assets/img/Graphic-Design-Hover.jpg) no-repeat
        center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      transition: 0.2s;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }
  }
}

Footer {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.children1_aboutMe {
  position: relative;
  top: 0;
  max-width: 503px;
  width: 100%;
  height: 590px;
  background: rgba(73, 93, 131, 0.7);
  box-shadow: 3px 6px 4px rgba(0, 0, 0, 0.15);
  z-index: 100;
  @media (min-width: 992px) {
    left: 136px;
  }
}

.children2_background {
  position: absolute;
  top: 110px;
  left: 0px;
  height: 587px;
  img {
    height: 587px;
    width: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-content {
  padding-top: 0;
  background-color: #fff;
  @media (min-width: 992px) {
    padding-top: 14rem;
  }
  .pic-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    .img-section {
      padding: 0 16px;
      text-align: center;
    }
    .low {
      margin-top: 0;
      @media (min-width: 992px) {
        margin-top: 123px;
      }
    }
  }
}

.ant-layout-footer {
  background: #fff !important;
  padding: 20rem 0 0 0 !important;
  .children1_aboutMe {
    text-align: center;
    .title {
      padding-top: 2.5rem;
      font-family: "Josefin Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 28px;
      color: #ffffff;
    }
    .content {
      font-family: "Josefin Sans";
      font-style: normal;
      font-weight: 250;
      font-size: 22px;
      line-height: 30px;
      color: #ffffff;
      text-align: left;
      padding: 1rem 4rem;
    }
  }
}
