.desktop {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}
.mobile {
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
}

.background {
  display: block;
  padding: 0 5rem;
  width: 80vw;
  margin: 0 auto;
  background: url(../../assets/img/kiwi_erix.png) no-repeat center center;
  background-size: cover;
  height: 32rem;
  margin-top: 15rem;
  position: relative;
}

.contactMe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 1.5rem;
}

.contact-title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  color: #0f244f;
}

.email {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #0f244f;
}

.social-title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #0f244f;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0 0.5rem;
  }
}

.resume {
  padding-top: 2.5rem;
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #0f244f;
}

.contactMe_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 13rem;
}
