.ant-menu {
  z-index: 100;
  background: rgba(0, 0, 0, 0) !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100px;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  .ant-menu-item {
    width: 250px;
    text-align: center;
  }
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: #495d83 !important;
  &:after {
    border-bottom-color: #495d83 !important;
  }
}

.ant-menu-horizontal {
  border-bottom-color: transparent;
  &:hover {
    border-bottom-color: transparent;
  }
  .ant-menu-item-active {
    &:after {
      border-bottom-color: #495d83 !important;
    }
  }
}
